import React from 'react'
import { Link,useParams } from 'react-router-dom'
import { Footer } from './Footer'
import { Header } from './Header'
import { Slider_anime } from './Slider_anime'

export const About = ({id}) => {


    return (
        <div>



            <Header />
            <Slider_anime id="Two"/>
           
            <section style={{ backgroundColor: '#1B1B1E' }}>
                <div className="container-fluid py-5">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-8 col-md-8">

                        </div>
                        <div className="col-lg-4 col-md-4 position-relative">
                            <div className="line">

                            </div>
                            <div className="text-start line_content pe-4">
                                <h3><b>Tech + Interio</b></h3>

                                <p>
                                    Minale + Mann is an established, award-winning design studio based in London, founded in 2006.
                                </p>
                                <p>
                                    The studio has expertise in a range of sectors including residential, workplace, hospitality and mixed-use projects, delivering design services to a range of projects across the UK, and internationally. Our ethos is to provide an integrated and cohesive approach to architecture and interior design in context with the environment.
                                </p>
                                <p>
                                    We employ a team of architects, interior and furniture designers. As a design collective, we work collaboratively to allow the creative process to evolve organically, alongside carefully considered design elements spanning macro and micro scales.
                                </p>
                                <p>
                                    Minale + Mann is also the first combined architecture and construction company in London, providing in-house construction team within its Create & Construct service.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="aboutmain">



                <div className="aboutus py-5">
                    <div className="container py-5">
                        <div className="row justify-contet-between ">
                            <div className="col-lg-4  right_about" >

                            </div>
                            <div className="col-lg-7 left_about">
                                <div className="ms-5 d-lg-block  d-none">
                                    <h2><b>Who We are</b></h2>
                                    <p>
                                    Since our establishment, we have designed and delivered cutting-edge structures for a variety of industries, including the workplace, housing, and placemaking.
                                    We strengthened our identity as a design-focused organisation with a wide skill set and experience that share a love for forward-thinking architecture and creative solutions.

                                    </p>
                                    <a href="#" className="btn btn-dark text-white px-5">know more</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about_bottom_banner my-5 " style={{ height: '70vh' }}>
                    <div className="container-fluid py-5">
                        <div className="row">
                            <div className="col-lg-6 " ></div>
                            <div className="col-lg-6" ></div>
                        </div>
                    </div>
                </div>




                {/* Category---- */}
                <section className="category mt-5">
                    <div className="container-fluid">
                        <div className="text-center">
                            <h2><b>Featured Project</b></h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 py-2">
                                <div className="category_box4 d-flex justify-content-center align-items-center">
                                    <Link href={`/product`} className="text-center btn text-dark" style={{ cursor: 'pointer', backgroundColor: '#fffce6', color: '#fff' }} to={`/product`} state={{ category: "Poplin", image: 'poplin.jpg' }} ><b>Interior Design</b></Link>
                                </div>
                            </div>
                            <div className="col-lg-4 py-2">
                                <div className="category_box5 d-flex justify-content-center align-items-center">
                                    <Link href={`/product`} className="text-center btn text-dark" style={{ cursor: 'pointer', backgroundColor: '#fffce6', color: '#fff' }} to={`/product`} state={{ category: "Polyester", image: 'polyseter.jpg' }} ><b>Alivation</b></Link>
                                </div>
                            </div>
                            <div className="col-lg-4 py-2">
                                <div className="category_box6 d-flex justify-content-center align-items-center">
                                    <Link href={`/product`} className="text-center btn text-dark" style={{ cursor: 'pointer', backgroundColor: '#fffce6', color: '#fff' }} to={`/product`} state={{ category: "Cotton linen", image: 'cotton_linen.jpg' }} ><b>Courtyards Design</b></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Category---- */}

                <section className="py-5 my-5" >
                    <div className="container" style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                        <div className="row">
                            <div className="col-lg-4 col-12 sideimg bg-dark">

                            </div>
                            <div className="col-lg-8 col-12 py-5">
                                <div className="text-start ">
                                    <h3>Our Journey</h3>
                                    <p>
                                        In the year 2002, the Todor firm was established in the Bhiwandi area of Maharashtra
                                        The company headquarter is located in Mumbai. Vijaya Textiles, Mahajan Textiles, and Laxmi Textiles are the three business divisions of the corporation. The company's textile sector has a distribution network in all of India's states, suitings are available in over 400 towns through 30000 retailers present in over 150 cities.

                                    </p>
                                    <p>
                                        In the year 2015, the company launched a new manufacturing activity to produce indigenous linen and cotton fabrics, as well as a variety of other materials. Also, to keep up with the growing demand for linen materials.
                                    </p>
                                    <p>
                                        This is presently the largest facility of its kind. The corporation established a new Combing Division throughout the year. Then came a period of vertical integration, which made it easier to process multi-fibres and enhance technology for blended fabrics. In the year 2019, Mahajan Fabrics also opened a readymade clothes plant in Bhiwandi.
                                    </p>




                                    <a href="#" className="btn  px-5 text-white" style={{ backgroundColor: '#010100' }}>Know More</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>



                {/* Founder---- */}
                <section className="founder pb-5">
                    <div className="container">
                        <div className="text-center py-3" >
                            <h2>Our Founders</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-6 founder_left">

                            </div>
                            <div className="col-md-6 right_text text-start py-3">
                                <div className="text-start">
                                    <h3>Ar. Vibhanshu Garg</h3>
                                    <h5>Founder (Tudor architect)</h5>
                                </div>
                                <p>
                                He is the founder of Tudor Arch Design and brings 5 years of extensive national & international 
                                architectural experience to the organization. He has worked with leading developers and organizations
                                like DLF, Arch10, ABM Consultants, Intec Infra Technologies etc. He specialized in the delivery of 
                                large-scale projects with strong command in design development, project management and site coordination
                                </p>
                            </div>
                        </div>
                        <div className="row flex-md-row flex-column-reverse">
                            <div className="col-md-6 right_text1 py-3">
                                <div className="text-start">
                                    <h3>Ar. Prerika Garg</h3>
                                    <h5>Founder (Tudor architect)</h5>
                                </div>
                                <p>
                                She is the co-founder of Tudor Arch Design. Having a masters degree in urban design, 
                                she brings 1 year and 6 months of academic experience in reputed universities and 6 months
                                of architectural experience to the organisation. She is having 2 research papers and 1 copyright on her name.
                                </p>
                            </div>
                            <div className="col-md-6 founder_left1">

                            </div>
                        </div>
                    </div>
                </section>
                {/* Founder---- */}



                {/* Our team--- */}
                <section className="team">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-lg-3 py-4 my-auto">
                                <div class="boxnew shadow-sm p-4">
                                    <div class="image-wrapper mb-3">
                                        <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
                                    </div>
                                    <div class="box-desc">
                                        <h5>Shubham</h5>
                                        <p>Profession</p>
                                    </div>
                                    <ul class="social">
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 py-4 my-auto">
                                <div class="boxnew shadow-sm p-4">
                                    <div class="image-wrapper mb-3">
                                        <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
                                    </div>
                                    <div class="box-desc">
                                        <h5>Shubham</h5>
                                        <p>Profession</p>
                                    </div>
                                    <ul class="social">
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 py-4 my-auto">
                                <div class="boxnew shadow-sm p-4">
                                    <div class="image-wrapper mb-3">
                                        <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
                                    </div>
                                    <div class="box-desc">
                                        <h5>Shubham</h5>
                                        <p>Profession</p>
                                    </div>
                                    <ul class="social">
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 py-4 my-auto">
                                <div class="boxnew shadow-sm p-4">
                                    <div class="image-wrapper mb-3">
                                        <img class="img-fluid" src="https://images.pexels.com/photos/555790/pexels-photo-555790.png" alt="..." />
                                    </div>
                                    <div class="box-desc">
                                        <h5>Jon Doe</h5>
                                        <p>FrontEnd Developer</p>
                                    </div>
                                    <ul class="social">
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                </section>
                {/* Our team--- */}


                <section className="bottom_banner text-white py-5" style={{ backgroundColor: '#010100' }}>
                    <div className="text-center">
                        <div className="logo">
                            {/* <h1 style={{ color: '#010100' }}>Mahajan<span style={{ color: '#010100', fontSize: '2.3rem', opacity: '0.3' }}>.</span> </h1> */}
                            {/* <img className='bg-white foteer_img' src={bg}  alt="" /> */}
                        </div>
                        <div>
                            <h3><b>EXPERIENCE THE CHARMS OF CLASSY AND AFFORDABLE ARCHITECTURE</b></h3>
                            <p>
                                Start your journey into the world of vibrant yet stylish <br /> and artistic designs.
                            </p>
                            <a href="/contact-us" className="btn btn-dark text-white">Contact Us</a>
                        </div>
                    </div>
                </section>



            </section>
            <Footer />
        </div>
    )
}
