import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import emailjs from 'emailjs-com';
import NumberScroller from 'react-number-scroller';

import { Footer } from './Footer'
import { Header } from './Header';
import { Slider_anime } from './Slider_anime';

export const Home = () => {



    const [number, setNumber] = useState(400);
    const [id, setid] = useState("Two");

    // useEffect(() => {
    //     setNumber(1000)
    // }, [])
    const form = useRef();

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_miu257w', 'template_15511h9', form.current, '2iISU3dq2OeUxT4El')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }

    useEffect(() => {
        let items = document.querySelectorAll('.carousel-item')

        items.forEach((el) => {

            const minPerSlide = 4
            let next = el.nextElementSibling
            for (var i = 1; i < minPerSlide; i++) {
                if (!next) {
                    next = items[0]
                }
                let cloneChild = next.cloneNode(true)
                el.appendChild(cloneChild.children[0])
                next = next.nextElementSibling
            }
        })
    }, [])

    const btnn = () => {
        // e.persist();
        let btn = document.querySelector('.menu_btn');
        let navbar = document.querySelector('.nav_icon');
        // document.querySelector('.menu_btn').onclick = function () {
        navbar.classList.toggle('mobile');
        btn.classList.toggle('fa-times');
        // }
    }
    // useEffect(() => {

    //     var btndesing = document.getElementById("parallax");
    //     window.addEventListener("scroll", function () {
    //         var logic = window.pageYOffset * 0.03;
    //         btndesing.style.backgroundPositionY = +logic + "px";
    //     })
    // }, [])

    useEffect(() => {
        //    alert('show')
        // const textForStorage = 'Hello World.';
        // sessionStorage.setItem('my-key', textForStorage);

        // const textFromStorage = sessionStorage.getItem('my-key');

        //        alert(textFromStorage)




        setTimeout(function () {
            document.querySelector('.preloader').style.transition = "all 0.8s ease"
            document.querySelector('.preloader').style.opacity = "0"
            document.querySelector('.preloader').style.visibility = "hidden"
        }, 6000);


        // alert(textFromStorage)


    }, [])

    //  <!-- SLick Effect Onscroll -->

    // const item = () => {
    // useEffect(() => {
    //     let myactive = document.querySelectorAll('.carousel-item');
    //     myactive.forEach(o => {
    //         o.addEventListener('click', evt => {
    //             alert(evt.target.id)
    //             console.log(evt.target.id)
    //         })
    //     })
    // }, [])

    // }

    const sliderope = (mm) => {
        setid(mm)
        // alert(mm)
        let slider = document.querySelector('#carouselExampleInterval');
        let coladd = document.querySelectorAll('.ad_col');
        slider.classList.toggle('mini_slide');
        coladd.forEach(x => {


            x.classList.remove('col-md-4')
            x.classList.add('col-md-12')
            x.style.transition = "all 0.4s ease"

        });
    }


   
    // useEffect(() => {

    //     window.on('scroll', function () {
    //         if (window.scrollTo > 200) {
    //             document.querySelector('.line').style.height="100%"
    //             document.querySelector('.line').style.transition = "0.5s"
    //         }
    //         else {
    //             document.querySelector('.line').style.height="0%"
    //             document.querySelector('.line').style.transition = "0.5s"
    //         }
    //     });

    // },[])





   



    return (

        <div>


            {/* preloader--- */}
            {/* <div className="preloader row justify-content-center align-items-center" style={{ height: '100vh', width: '100%', zIndex: '4', top: '0', left: '11px', backgroundColor: '#000', position: 'fixed' }} >
                <div className="text-center">
                <img src={require('./images/anim_logo.png')}  className="img-fluid col-lg-2 mx-auto " alt="" />
                    
                </div>
            </div> */}
            {/* preloader--- */}


            {/* <!-- <Header Start============ --> */}
            <Header />

            {/* <Slider_anime id="One"  /> */}


            <section style={{ backgroundColor: '#1B1B1E' }}>
                <div className="container-fluid py-5">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-8 col-md-8">

                        </div>
                        <div className="col-lg-4 col-md-4 position-relative">
                            <div className="line">

                            </div>
                            <div className="text-start line_content pe-4">
                                <h3><b>Tech + Interio</b></h3>

                                <p>
                                    Minale + Mann is an established, award-winning design studio based in London, founded in 2006.
                                </p>
                                <p>
                                    The studio has expertise in a range of sectors including residential, workplace, hospitality and mixed-use projects, delivering design services to a range of projects across the UK, and internationally. Our ethos is to provide an integrated and cohesive approach to architecture and interior design in context with the environment.
                                </p>
                                <p>
                                    We employ a team of architects, interior and furniture designers. As a design collective, we work collaboratively to allow the creative process to evolve organically, alongside carefully considered design elements spanning macro and micro scales.
                                </p>
                                <p>
                                    Minale + Mann is also the first combined architecture and construction company in London, providing in-house construction team within its Create & Construct service.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* Products--- */}
            <section className="products_list mt-5">
                <div className="container">
                    <div className="text-center">
                        <h2>Featured Products</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12 pt-3">
                            <div className="img_box rounded position-relative ">
                                <img src={require('./images/img104.jpg')} className="img-fluid" alt="" />
                                <div className="lower_info p-4">
                                    <div className="text-center">
                                        <h5>Product Heading</h5>
                                        <a href="#" className="btn btn-success rounded btn-sm">View More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 pt-3">
                            <div className="img_box rounded position-relative ">
                                <img src={require('./images/img104.jpg')} className="img-fluid" alt="" />
                                <div className="lower_info p-4">
                                    <div className="text-center">
                                        <h5>Product Heading</h5>
                                        <a href="#" className="btn btn-success rounded btn-sm">View More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 pt-3">
                            <div className="img_box rounded position-relative ">
                                <img src={require('./images/img104.jpg')} className="img-fluid" alt="" />
                                <div className="lower_info p-4">
                                    <div className="text-center">
                                        <h5>Product Heading</h5>
                                        <a href="#" className="btn btn-success rounded btn-sm">View More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                        
                        
                    </div>
                </div>
            </section>
            {/* Products--- */}


            <Footer />

        </div>

    )
}
